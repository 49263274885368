import React from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import IntroPage from 'src/px/IntroPage';
import { locale } from 'src/utils';
import paths from 'src/constants/paths';

const QUERY = graphql`
  query ExplorerFaq {
    en: allGraphCmsFaq(filter: { contexts: { in: health_explorer }, locale: { eq: en } }) {
      nodes {
        slug
        title
        explanation
      }
    }
    es: allGraphCmsFaq(filter: { contexts: { in: health_explorer }, locale: { eq: es } }) {
      nodes {
        slug
        title
        explanation
      }
    }
  }
`;

const SlasherIntroView = ({}) => {
  const data = useStaticQuery(QUERY);
  return (
    <IntroPage
      flow="Explorer"
      onNext={() => navigate(paths.EXPLORER_ZIP)}
      faqs={data?.[locale]?.nodes}
    />
  );
};

export default SlasherIntroView;
